//local variables
$max-input-width: 416px;
$landing-image-Height: 310px;

$font-fordmotion: 'FordMotion', sans-serif;
$font-inter: 'Inter', sans-serif;
$font-ford-f1: 'Ford-F1', sans-serif;
$font-ford-f1-semibold: 'FordF1-Semibold', sans-serif;

$font-xxxxsmall: 8px;
$font-xxxsmall: 10px;
$font-xxsmall: 12px;
$font-xsmall: 14px;
$font-small: 16px;
$font-medium: 18px;
$font-large: 20px;
$font-xlarge: 22px;
$font-xxlarge: 24px;
$font-xxxlarge: 28px;
$font-xxxxlarge: 32px;
$font-xxxxxlarge: 40px;

$font-weight-regular: 300;
$font-weight-medium: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;

// line height
$lineheight-xxsmall: 12px;
$lineheight-xsmall: 16px;
$lineheight-small: 20px;
$lineheight-medium: 24px;
$lineheight-large: 28px;
$lineheight-xlarge: 32px;
$lineheight-xxlarge: 40px;
$lineheight-xxxlarge: 48px;

// spacing
$spacing-px: 1px;
$spacing-1x: 4px;   // 0.25rem
$spacing-2x: 8px;   // 0.5rem
$spacing-3x: 12px;  // 0.75rem
$spacing-4x: 16px;  // 1rem
$spacing-5x: 20px;  // 1.25rem
$spacing-6x: 24px;  // 1.5rem
$spacing-7x: 28px;  // 1.75rem
$spacing-8x: 32px;  // 2rem
$spacing-9x: 36px;  // 2.25rem
$spacing-10x: 40px; // 2.5rem
$spacing-11x: 44px; // 2.75rem
$spacing-12x: 48px; // 3rem
$spacing-13x: 52px; // 3.25rem
$spacing-14x: 56px; // 3.5rem
$spacing-15x: 60px; // 3.75rem
$spacing-16x: 64px; // 4rem
$spacing-17x: 68px;
$spacing-18x: 72px;
$spacing-19x: 76px;
$spacing-20x: 80px;
$spacing-21x: 84px;
$spacing-22x: 88px;
$spacing-24x: 96px;
$spacing-25x: 100px;
$spacing-28x: 112px;
$spacing-32x: 128px;

// media
$screen-xs: 0px;
$screen-s: 576px;
$screen-m: 768px;
$screen-l: 1024px;
$screen-xl: 1200px;
$media-xs: '(min-width: #{$screen-xs})';
$media-s: '(min-width: #{$screen-s})';
$media-m: '(min-width: #{$screen-m})';
$media-l: '(min-width: #{$screen-l})';
$media-xl: '(min-width: #{$screen-xl})';

// colors
$color-amber100: #f8ede6;
$color-amber200: #f1dccc;
$color-amber300: #eacab3;
$color-amber400: #e3b899;
$color-amber500: #dda780;
$color-amber600: #d69566;
$color-amber700: #cf834d;
$color-amber800: #c87133;
$color-amber900: #c16019;
$color-amber1000: #ba4e00;
$color-amber1100: #a74600;
$color-amber1200: #953e00;
$color-amber1300: #823700;
$color-amber1400: #5d2700;
$color-fordBlue100: #e6e6ef;
$color-fordBlue200: #cccede;
$color-fordBlue300: #b3b5ce;
$color-fordBlue400: #999dbd;
$color-fordBlue500: #8084ad;
$color-fordBlue600: #666b9d;
$color-fordBlue700: #4d538c;
$color-fordBlue800: #333a7c;
$color-fordBlue900: #1a226b;
$color-fordBlue1000: #00095b;
$color-fordBlue1100: #000852;
$color-fordBlue1200: #000749;
$color-fordBlue1300: #000640;
$color-fordBlue1400: #00052e;
$color-grabber100: #e8e6fe;
$color-grabber200: #d1ccfd;
$color-grabber300: #b9b3fc;
$color-grabber400: #a299fb;
$color-grabber500: #8b80fa;
$color-grabber600: #7466f8;
$color-grabber700: #5d4df7;
$color-grabber800: #4533f6;
$color-grabber900: #2e19f5;
$color-grabber1000: #1700f4;
$color-grabber1100: #1500dc;
$color-grabber1200: #1200c3;
$color-grabber1300: #1000ab;
$color-grabber1400: #0c007a;
$color-green100: #e6f3e6;
$color-green200: #cce6cc;
$color-green300: #b3dab3;
$color-green400: #99cd99;
$color-green500: #80c180;
$color-green600: #66b466;
$color-green700: #4da84d;
$color-green800: #339b33;
$color-green900: #198f19;
$color-green1000: #008200;
$color-green1100: #007500;
$color-green1200: #006800;
$color-green1300: #005b00;
$color-green1400: #004100;
$color-magenta800: #f644bb;
$color-magenta900: #db199a;
$color-magenta1000: #b11c7e;
$color-magenta1100: #981a6c;
$color-magenta1200: #7f165b;
$color-magenta1300: #650f48;
$color-magenta1400: #4b0735;
$color-neutral000: #ffffff;
$color-neutral100: #fafafb;
$color-neutral200: #f5f6f7;
$color-neutral300: #e6e8ea;
$color-neutral400: #d1d5d9;
$color-neutral500: #9ca3ad;
$color-neutral600: #6b7786;
$color-neutral700: #4d5b6d;
$color-neutral800: #38485c;
$color-neutral900: #1f3047;
$color-neutral1000: #00142e;
$color-neutral1100: #001025;
$color-neutral1200: #000000;
$color-orange800: #e67205;
$color-orange900: #c85e0e;
$color-orange1000: #a84a0e;
$color-orange1100: #973f0c;
$color-orange1200: #86350a;
$color-orange1300: #742a06;
$color-orange1400: #612004;
$color-purple800: #bb6ff9;
$color-purple900: #a15be0;
$color-purple1000: #8746c6;
$color-purple1100: #793ab8;
$color-purple1200: #6b2eaa;
$color-purple1300: #5d1f9c;
$color-purple1400: #50098e;
$color-red100: #fbeae7;
$color-red200: #f7d5ce;
$color-red300: #f3c0b6;
$color-red400: #efab9d;
$color-red500: #eb9685;
$color-red600: #e6816c;
$color-red700: #e26c54;
$color-red800: #de573b;
$color-red900: #da4223;
$color-red1000: #d62d0a;
$color-red1100: #c12909;
$color-red1200: #ab2408;
$color-red1300: #962007;
$color-red1400: #6b1705;
$color-skyview100: #e6f1fd;
$color-skyview200: #cde2fc;
$color-skyview300: #b4d4fa;
$color-skyview400: #9bc5f9;
$color-skyview500: #83b7f7;
$color-skyview600: #6aa9f5;
$color-skyview700: #519af4;
$color-skyview800: #388cf2;
$color-skyview900: #1f7df1;
$color-skyview1000: #066fef;
$color-skyview1100: #0564d7;
$color-skyview1200: #0559bf;
$color-skyview1300: #044ea7;
$color-skyview1400: #033878;
$color-skyview1500: #0562d2;
$color-teal800: #00a5a5;
$color-teal900: #088d95;
$color-teal1000: #0c7483;
$color-teal1100: #0b6778;
$color-teal1200: #085a6e;
$color-teal1300: #034c60;
$color-teal1400: #003f54;
$color-twilight000: #ffffff;
$color-twilight100: #fafafb;
$color-twilight200: #f5f6f7;
$color-twilight300: #e6e8ea;
$color-twilight400: #d1d5d9;
$color-twilight500: #9ca3ad;
$color-twilight600: #6b7786;
$color-twilight700: #4d5b6d;
$color-twilight800: #38485c;
$color-twilight900: #1f3047;
$color-twilight1000: #00142e;
$color-twilight1100: #001025;
$color-twilight1200: #000000;
$color-twilight1300: #000e20;
$color-twilight1400: #000a17;
$color-yellow100: #fff9e5;
$color-yellow200: #fff2cc;
$color-yellow300: #ffecb2;
$color-yellow400: #ffe699;
$color-yellow500: #ffdf80;
$color-yellow600: #ffd966;
$color-yellow700: #ffd34d;
$color-yellow800: #ffcd33;
$color-yellow900: #ffc61a;
$color-yellow1000: #ffc000;
$color-yellow1100: #e5ad00;
$color-yellow1200: #cc9a00;
$color-yellow1300: #b28600;
$color-yellow1400: #664d00;

// opacity

$opacity-dark00: #ffffff00;
$opacity-dark10: #ffffff1a;
$opacity-dark20: #ffffff33;
$opacity-dark30: #ffffff4d;
$opacity-dark40: #ffffff66;
$opacity-dark50: #ffffff80;
$opacity-dark60: #ffffff99;
$opacity-dark70: #ffffffb3;
$opacity-dark80: #ffffffcc;
$opacity-dark90: #ffffffe6;
$opacity-dark100: #ffffff;
$opacity-light00: #00142e00;
$opacity-light10: #00142e14;
$opacity-light20: #00142e33;
$opacity-light30: #00142e4d;
$opacity-light40: #00142e66;
$opacity-light50: #00142e80;
$opacity-light60: #00142e99;
$opacity-light70: #00142eb3;
$opacity-light80: #00142ecc;
$opacity-light90: #00142ee6;
$opacity-light100: #00142e;
$opacity-disabled00: #00000061;