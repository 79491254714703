@import '../../../styles/variables.scss';

.dealerReviewWrapper {
  padding: $spacing-12x;
}

.dealerLink {
  color: $color-skyview1100;
  p {
    font-size: $font-small;
    line-height: $lineheight-medium;
  }
}

.dealerTitle {
  color: $color-twilight1000;
  font-weight: $font-weight-semibold;
  font-size: $font-medium;
}

.mapWrapper {
  display: flex;
  gap: $spacing-5x;
  align-items: center;
  margin-top: $spacing-4x;
}

.linkWrapper {
  font-size: $font-small;
  a {
    font-size: inherit;
  }
}
