@import '../../styles/variables';

.container {
  width: 100%;
  padding: 0%;

  b {
    font-weight: 500;
  }
}

.gridStyles {
  margin: $spacing-10x 0 $spacing-5x 0;
}

.gridItems {
  padding-bottom: $spacing-4x !important;
}

.headerImage {
  width: 100%;
  border-radius: $spacing-2x;
  object-fit: cover;
  object-position: top;
}

.applicationSection {
  max-width: $screen-l;
  display: flex;
  flex-flow: column;
  gap: $spacing-8x;
  ul {
    li {
      font-family: $font-inter !important;
      margin: $spacing-4x 0 !important;
    }
  }
}

.startAppBtn {
  margin-top: $spacing-12x;
}

@media only screen and (max-width: $screen-s) {
  .startAppBtn button {
    width: 100%;
  }
}
