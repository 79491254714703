@import '../../../../styles/variables.scss';

.active {
  background-color: $color-skyview1500 !important;
  color: $color-neutral000 !important;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-top: $spacing-3x;
  gap: $spacing-3x;

  button {
    min-width: 100px;
  }
}

.buttonGroupTitleWrapper {
  display: flex;
}

.tooltip {
  color: $color-skyview1000;
  margin-inline: $spacing-1x;
}
