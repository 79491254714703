
.headerWrapper {
    :global {
        .font-medium {
            font-size: 12px !important;
        }

        .header {
            max-width: 100%;
        }
    }
}