@import '../../../../styles/variables';

.buttonWrapper {
    display: flex;
    gap: $spacing-6x;
}

@media only screen and (max-width: $screen-s) {
    .buttonWrapper {
        flex-flow: column;
     }
}