@import '../../../styles/variables.scss';

.feedbackMain {
  width: fit-content !important;
  :global {
    div {
      [class*='accordionItem__ContentWrapper'] {
        height: auto !important;
        max-height: fit-content !important;
      }
    }
  }

  fieldset {
    [class*='radioGroup__RadioGroupWrapper'] {
      :global {
        label {
          font-family: $font-ford-f1-semibold;
        }
      }
    }
  }
}

.feedbackRatingContainer {
  display: flex;
  flex-flow: row wrap;
  gap: $spacing-2x;
}

.feedbackGrid {
  gap: $spacing-10x 0;
}

.active {
  background-color: $color-skyview100 !important;
}

.submitWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-3x;
}

.ratingCol2 {
  width: fit-content;
  span {
    align-self: flex-end;
  }
}

.radioButtonWrapper {
  label[class*='RadioButton__label'] {
    font-family: $font-inter !important;
  }
  
}
