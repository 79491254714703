@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.st {
  margin: $spacing-9x 0;
}

.step {
  h4 {
    max-width: 100px !important;
    margin: auto !important;
  }
}
