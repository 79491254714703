@import './variables.scss';
@import './mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
  font-family: $font-inter, sans-serif;
}

// Font override for ford credit header
@font-face {
  font-family: 'fordngbs-antenna-medium';
  src: url('../assets/fonts/FordF1-Semibold.otf');
  font-weight: 600;
}

.header {
  .hidden {
    .grid:nth-child(2) {
      display: none;
    }
  }
}

html {
  font-size: $font-small; // Default font size for COCA.
  color: $color-neutral1000;
}

body {
  margin: 0;
  padding: 0;
}

p,
ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

ul {
  padding-inline-start: 40px;
}

a:hover {
  text-decoration: none !important;
}

* {
  [class*='button__StyledButton'],
  [class*='checkbox__LabelContainer'] {
    font-family: $font-inter !important;
    font-weight: $font-weight-medium !important;
  }

  [class*='button__StyledButton'] {
    border-radius: $spacing-2x !important;
  }

  [class*='textInput__TextInputWrapper'] > div {
    margin-bottom: 0 !important;
  }
}

div {
  [class*='textInput__TextInputWrapper'] {
    span {
      margin: 0;
    }
  }

  [class*='incrementSelector__InnerWrapper'] {
    min-height: 42px;
  }

  [class*='incrementSelector__OuterWrapper'] {
    .sc-beySPh {
      margin-bottom: 0 !important;
      text-align: center;
    }
  }

  [class*='progressTrackerVertical__ProgressWrapper'] {
    position: relative;
  }

  [class*=' CheckBox__label-container'] {
    font-family: $font-inter !important;
  }

  [class*='progressTrackerVertical__LabelWrapper'] {
    font-weight: $font-weight-semibold !important;
  }

  [class*='accordionItem__AccordionItemWrapper'] {
    outline: 1px solid $color-neutral400 !important;
    border: none !important;
  }

  [class*='card__Content'] {
    padding: $spacing-4x $spacing-6x !important;
    height: 100%;
  }

  [class*='inLineAlert__MainWrapper'] {
    align-items: center;
  }

  [class*='inLineAlert__LogoWrapper'] {
    padding-top: 0;
  }

  [class*='inLineAlert__InLineAlertContentWrapper'] {
    height: auto;
  }

  [class*='popupTip__MainWrapper'] {
    color: $color-skyview1000;
  }

  [class*='modalWindow__ModalWindowWrapper'] {
    align-items: center;
    #modal-container {
      border-radius: 16px;
    }
  }

  [class*='checkbox__Wrapper'] {
    padding-left: $spacing-1x;
  }
}

.sc-eDLKkx {
  line-height: 0 !important;
}

.layout-container {
  margin: $spacing-4x $spacing-25x $spacing-24x;
}

.divider {
  margin-block: $spacing-5x;
}

.cf-field-title {
  margin-top: $spacing-4x;
}

.cf-field {
  margin-top: $spacing-6x;
}

.cf-bottom-field {
  margin-bottom: $spacing-6x;
}

.cf-block {
  margin-top: $spacing-12x;
}

.cf-nav {
  margin-top: $spacing-14x;
}

.cf-field-spacing {
  margin-inline: $spacing-4x;
}

.progress-tracker {
  [class*='progressTrackerVertical__ChildrenContainer'] {
    min-height: 52px;
    width: 100%;
  }

  [class*='progressTrackerVertical__ProgressWrapper']:last-child {
    [class*='progressTrackerVertical__ChildrenContainer'] {
      min-height: 0px !important;
    }
    [class*='progressTrackerVertical__ChildrenWrapper']:not(:has(div)) {
      margin: 0;
    }
  }
}

.input-wrapper {
  [class*='textInput__HelperTextWrapper'] {
    font-weight: 300;
  }
}

.dropdownMenu__ContentList {
  [class*='dropdownMenuItem__ListWrapper']:disabled {
    pointer-events: none;
    button {
      pointer-events: none;
    }
  }
}

button {
  [class*='Button--disabled'] {
    pointer-events: none;
  }
}

.cancel-btn {
  padding-inline: 0 !important;
  width: fit-content !important;
}

// AEM Overrides

.rich-editor {
  p,
  li {
    color: $color-neutral800 !important;
    font-size: inherit !important;
  }
}

.secondary-text-editor {
  p {
    font-size: inherit !important;
    color: $color-twilight800 !important;
  }
}

.error-text {
  color: $color-red1000;
  & p {
    color: $color-red1000 !important;
  }
}

// Form Styles

.field-error {
  display: flex;
  font-family: $font-inter;
  font-size: $font-xsmall;
  color: $color-red1000;
  gap: $spacing-2x;
  margin-block: $spacing-4x;
  align-items: center;
}

input {
  font-weight: 400 !important;
  &::placeholder {
    font-weight: 400 !important;
  }
}

.read-only-wrapper {
  display: flex;
  flex-flow: column;
  gap: $spacing-3x;
  color: $color-neutral600;
}

.info-message {
  color: $color-neutral700;
  font-size: $font-xsmall;
}

.modify-success {
  [class*='inLineAlert__InLineTitleWrapper'] {
    font-size: $font-xsmall !important;
  }

  [class*='inLineAlert__MainWrapper'] {
    align-items: baseline;
  }
}

.te-alert {
  [class*='inLineAlert__LogoWrapper'] {
    display: none;
  }

  [class*='inLineAlert__InLineTitleWrapper'] {
    font-size: $font-xsmall;
  }

  [class*='inLineAlert__InLineBodyWrapper'] {
    font-size: $font-xsmall;
    font-weight: $font-weight-medium;
  }
}

.inline-alert-no-body {
  .InLineAlert__content-body {
    display: none;
  }
}

.protected-svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

// Breakpoints

@media only screen and (max-width: $screen-m) {
  .layout-container {
    margin: $spacing-4x !important;
  }
}

@media only screen and (max-width: $screen-m) {
  div {
    [class*='progressTrackerVertical__ChildrenWrapper'] {
      margin-left: 39px;
    }

    [class*='modalWindow__CloseButtonWrapper'] {
      top: 0 !important;
    }
  }
}
