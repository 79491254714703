@import './variables.scss';
@import './mixins.scss';
//Font Utils

.font-xxxsmall {
  font-size: $font-xxxsmall;
}

.font-xxsmall {
  font-size: $font-xxsmall;
}

.font-xsmall {
  font-size: $font-xsmall;
  line-height: $lineheight-small;
}

.font-small {
  font-size: $font-small;
  line-height: $lineheight-medium;
}

.font-medium {
  font-size: $font-medium;
}

.font-large {
  font-size: $font-large;
}

.font-xlarge {
  font-size: $font-xlarge;
}

.font-xxlarge {
  font-size: $font-xxlarge;
}

.font-xxxlarge {
  font-size: $font-xxxlarge;
}

.font-xxxxlarge {
  font-size: $font-xxxxlarge;
}

.font-xxxxxlarge {
  font-size: $font-xxxxxlarge;
}

.font-weight-regular {
  font-weight: $font-weight-regular;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.color-neutral {
  color: $color-neutral800;
}

.color-neutral-600 {
  color: $color-neutral600;
}

.color-neutral-700 {
  color: $color-neutral700;
}

.color-neutral-800 {
  color: $color-neutral800;
}

.color-neutral-1000 {
  color: $color-neutral1000;
}

.color-twilight-1000 {
  color: $color-twilight1000;
}

.text-center {
  text-align: center;
}

.m-0 {
  margin: 0;
}

.fit-content {
  width: fit-content;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-flow: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

// Padding utils

@for $i from 0 through 20 {
  .pt-#{$i} {
    padding-top: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .pb-#{$i} {
    padding-bottom: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .pl-#{$i} {
    padding-left: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .pr-#{$i} {
    padding-right: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .p-#{$i} {
    padding: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .px-#{$i} {
    padding-inline: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .py-#{$i} {
    padding-block: get-spacing-value($i);
  }
}

// Margin Utils

@for $i from 0 through 20 {
  .mt-#{$i} {
    margin-top: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .mb-#{$i} {
    margin-bottom: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .mr-#{$i} {
    margin-right: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .ml-#{$i} {
    margin-left: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .m-#{$i} {
    margin: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .mx-#{$i} {
    margin-inline: get-spacing-value($i);
  }
}

@for $i from 0 through 20 {
  .my-#{$i} {
    margin-block: get-spacing-value($i);
  }
}

// Gap Utils
@for $i from 0 through 20 {
  .gap-#{$i} {
    gap: get-spacing-value($i);
  }
}

@for $i from 0 through 8 {
  .gap-y-#{$i} {
    gap: get-spacing-value($i) 0;
  }
}

@for $i from 0 through 8 {
  .gap-x-#{$i} {
    gap: 0 get-spacing-value($i);
  }
}

// Font Utils

.font-ford-f1-semibold {
  font-family: $font-ford-f1-semibold;
}

.font-inter {
  font-family: $font-inter;
}
