@import '../../../styles/variables';

.ecocaWrapper {
  color: black;
  font-size: $font-xsmall;
  padding: $spacing-3x;
  border: $spacing-px solid;
  border-radius: $spacing-1x;
  max-height: 300px;
  overflow: auto;
  ul li {
    list-style-type: none;
    &:before {
      content: '• ';
      margin-left: -1em;
    }
  }
  p,
  ul,
  li {
    font-size: $font-xsmall !important;
  }
  ol,
  ul {
    padding-inline-start: 40px;
  }
  b {
    font-weight: 500;
  }

  .fordBlueImg {
    img {
      height: 40px;
      width: auto;
      object-fit: contain;
      margin-left: -10px;
    }
  }

  .addressContainer {
    display: flex;
    flex-flow: column;
    margin: $spacing-8x 0 $spacing-15x;

    span {
      &:first-child {
        text-transform: uppercase;
      }
    }
  }

  .fordBlueAddress {
    p {
      font-size: $font-xxxxsmall !important;
      line-height: $lineheight-xxsmall;
      color: black;
      position: relative;
      left: 70px;
      bottom: 10px;
    }
  }
}
.savePdfWrapper {
  display: flex;
  gap: 20px;
  align-items: center;

  .spinner {
    position: relative;
    top: 8px;
  }
}
