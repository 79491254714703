@import '../../../styles/variables';

.searchWrapper {
  display: flex;
  gap: $spacing-6x;
  align-items: center;
}

.dealerGrid {
  gap: $spacing-4x 0;
  margin-block: $spacing-8x;
  :global {
    div {
      [class*='grid__GridItemWrapper'] > div, [class*='grid__GridItemWrapper'] > div > div {
        height: 100%;
      } 
    }
  }
}

.dealerCard {
  display: flex;
  flex-flow: column;
  gap: $spacing-2x;
}

.dealerTitle {
  color: $color-neutral1000;
}

.dealerPhoneNumber {
  color: $color-skyview1100;
  font-weight: $font-weight-semibold;
  font-size: $font-small;
}

.selectedDealer {
  color: $color-green1000;
  height: $spacing-4x;
}

.commercialVehicleCenter {
  color: $color-grabber1000;
  font-size: $font-xxsmall;
}

.dealerMap {
  display: flex;
  flex-flow: row wrap;
  margin-block: $spacing-3x $spacing-5x;
  font-size: $font-xsmall;
  gap: $spacing-1x;
}

.selectVehicleButton {
  gap: $spacing-2x;
  max-width: 100%;
}

.selectVehicleButtonDisabled {
  gap: $spacing-2x;
  max-width: 100%;
  background-color: $color-neutral400 !important;
  cursor: not-allowed !important;
}

.disabledPagination {
  ul {
    display: flex;
    justify-content: center;
    pointer-events: none;
    a {
      color: $color-neutral000 !important;
      background-color: $color-neutral400 !important;
      cursor: not-allowed !important;
    }
    button {
      color: $color-neutral400 !important;
      cursor: not-allowed !important;
    }
  }
}

.nodealerFound {
  font-family: $font-ford-f1-semibold;
  font-size: $font-xxlarge;
}

.pagination {
  display: flex;
  justify-content: center;
}

.dealerCardGrid {
  height: 100%;
}

.dealerContainer {
  display: flex;
  flex-flow: column;
  height: calc(100% - 16px);
  justify-content: space-between;
}

@media only screen and (max-width: $screen-s) {
  .searchDealerBtn {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
