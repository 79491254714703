@import '../../styles/variables';

.trustedText {
  color: var(--semantic-color-text-subtle, $color-neutral800);
  font-family: $font-inter, sans-serif;
  font-size: $font-xsmall;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: $lineheight-medium;
  margin: $spacing-10x 0 $spacing-4x 0;
}

.trustedIconText {
  margin-bottom: $spacing-10x;
}

.customerAcknowledgementTitleAlign {
  display: flex;
  align-items: center;
  margin-top: $spacing-3x;
}

.customerAcknowledgementTitle {
  color: var(--semantic-color-text-default, $color-neutral1000);
  font-family: $font-ford-f1-semibold, sans-serif;
  font-size: $font-xxlarge;
  font-style: normal;
  font-weight: $font-weight-semibold;
  line-height: $lineheight-xlarge;
  margin-bottom: 0;
}

.nextStepBlue {
  color: $color-skyview1200;
  margin-top: $spacing-4x;
  p,
  b {
    font-family: $font-ford-f1-semibold, sans-serif;
    color: $color-skyview1200;
  }
}

.dealerInfo {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  margin-bottom: $spacing-2x;
}

.nextStepBlue h4 {
  color: $color-skyview1000 !important;
}

.nextStepWrapper {
  color: $color-twilight800;
  font-size: $font-small;
  line-height: $lineheight-medium;
  padding-block: $spacing-1x !important;
  list-style-type: disc;
  padding: 0 $spacing-8x $spacing-6x;
  margin-top: 0;

  li::marker {
    color: $color-skyview1000;
    padding-bottom: $spacing-2x;
  }
}

.dealerName {
  color: var(--semantic-color-text-default, $color-neutral1000);
  font-family: $font-inter, sans-serif;
  font-size: $font-small;
  font-style: normal;
  font-weight: $font-weight-semibold;
  line-height: $lineheight-medium;
}

.dealerAddress {
  color: var(--semantic-color-text-default, $color-neutral1000);
  font-family: $font-inter, sans-serif;
  font-size: $font-xsmall;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: $lineheight-small;
  margin-bottom: $spacing-4x;
}

.dealerDivider {
  margin: $spacing-4x 0;
}

.applicationDetails {
  margin-bottom: $spacing-4x;
  height: auto !important;
  flex-grow: 1;

  h6 {
    display: none;
  }
}

.dealerDetails {
  h6 {
    font-size: $font-large !important;
    font-family: $font-ford-f1-semibold !important;
  }
}

.vehicleDetails {
  color: $color-grabber1000;
  margin: 0;
}

.dealerWrapper {
  color: $color-neutral1000;
  display: flex;
  flex-flow: column;
  font-family: $font-inter;
}

.dealerText {
  p {
    font-family: $font-ford-f1-semibold !important;
    font-size: $font-large;
  }
}

.applicationNumberContainer {
  display: flex;
  gap: $spacing-4x;
  flex-flow: wrap;
  margin-bottom: $spacing-6x;
}

.clocBannerImage {
  img {
    width: 100%;
    max-height: 270px;
  }
}

.clocWrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: $spacing-4x;
}

.dealerAddressWrapper {
  color: $color-skyview1100;
  margin-top: $spacing-3x;
}

.vehicleTitleWrapper, .vehicleTitleWrapper p {
  font-size: $font-large !important;
  line-height: $lineheight-large !important;
  font-family: $font-ford-f1-semibold !important;
  color: $color-neutral1000;
}

.applicationDetailsGrid {
  margin-inline: -$spacing-3x;
}

.vehicleIndexIcon {
  background: $color-fordBlue1000;
  color: $color-twilight000;
  height: $spacing-6x;
  width: $spacing-6x;
  text-align: center;
  border-radius: 50%;
  margin-right: $spacing-3x;
  margin-top: 2px;
}

.applicationNumberTitle, .applicationNumberExpressTitle {
  p {
    font-family: $font-ford-f1-semibold !important;
  }
}

.applicationNumberExpressTitle {
  p {
    font-size: $font-large !important;
  }
}
