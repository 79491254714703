@import '../../../styles//variables.scss';

.quantityText {
  font-size: $spacing-3x;
  margin-inline: $spacing-2x;
}

.vehicleImageViewer {
  display: flex;
  align-items: center;
  gap: $spacing-3x;
  flex-flow: column;
}

.vehicleCarousel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: $spacing-3x;
  gap: $spacing-2x;
  font-size: $font-xsmall;
}

.colorSquare {
  height: 12px;
  width: 12px;
  display: inline-block;
  margin-right: $spacing-3x;
}
