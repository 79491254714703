@import '../../../styles/variables';

.disableExpressVehicle {
  pointer-events: none;
  opacity: 0.3;
}

.toggleContainer {
  display: flex;
  flex-flow: row wrap;
  gap: $spacing-2x;
  margin: $spacing-7x 0 $spacing-5x;

  span {
    [class*='switch__SwitchSliderIcon']:not([class*='Switch__slider-icon--checked']) {
      display: none !important;
    }
  }
}

.downPaymentText {
  input {
    text-align: right;
  }
}

.totalQuantityWrapper {
  display: flex;
  gap: $spacing-2x;

  .vehicleCount {
    color: $color-skyview1000;
    font-weight: $font-weight-bold;
    font-size: $font-large;
  }
}

.clocAlertYes {
  h4,
  p {
    font-size: $font-xsmall;
    font-weight: $font-weight-bold;
    font-family: $font-ford-f1-semibold;
  }
}

.clocAlertNo {
  [class*='richTextEditor__RichTextEditorWrapper'] {
    b {
      font-weight: $font-weight-bold !important;
      font-family: $font-ford-f1-semibold;
    }
    p {
      color: inherit;
      font-size: $font-xsmall;
    }
  }
}

.maxQuantityAlert {
  [class*='inLineAlert__InLineTitleWrapper'] {
    font-size: $font-xsmall;
    line-height: $lineheight-small;
    font-weight: $font-weight-medium;
  }
}
