@import '../../styles/variables';


.trustedTextRow {
  display: flex;
}

.trustedIcon {
  margin-right: $spacing-2x;
}

.reviewGridWrapper {
  margin: 32px 0;
}

.reviewItem {
  margin-bottom: $spacing-6x;
}

.cardMain {
  max-width: 100% !important;
  height: 100%;
}

.cardheader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.cardBody {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.cardTitle {
  color: $color-neutral1000;
  font-size: $font-xxlarge;
  font-style: normal;
  font-weight: $font-weight-semibold;
  line-height: $lineheight-xlarge;
  margin: 0;
  float: left;
}

.modifyButton {
  width: max-content !important;
  float: right;
}

.dealerInfo {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: $spacing-9x $spacing-25x;
  margin-top: $spacing-9x;
}

.dealerName {
  color: $color-neutral800;
  font-family: $font-inter, sans-serif;
  font-size: $font-medium;
  font-style: normal;
  font-weight: $font-weight-semibold;
  line-height: $lineheight-large;
}

.dealerAddress {
  color: $color-skyview1100;
  font-family: $font-inter, sans-serif;
  font-size: $font-medium;
  font-style: normal;
  font-weight: $font-weight-semibold;
  line-height: $lineheight-large;
}

.dealerDistance {
  color: #000;
  font-family: $font-inter, sans-serif;
  font-size: $font-small;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: $lineheight-medium;
}

.infoItem {
  margin-top: $spacing-8x;
}

.infoText {
  color: var(--semantic-color-text-placeholder, $color-neutral600);
  font-family: $font-inter, sans-serif;
  font-size: $font-xsmall;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: $lineheight-small;
}

.termsCard {
  max-width: 100% !important;
  height: 400px;
}

.termsInnerBox {
  overflow-y: scroll;
  max-height: 335px;
}

.checkboxLabel {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.checkboxLabelDisabled {
  display: flex;
  width: 100%;
  cursor: default;
}

.checkboxTitle > p {
  margin-right: $spacing-1x;
  color: $opacity-light100;
}

.checkboxSub > p {
  color: $opacity-light100;
}

.checkboxTitleDisabled > p {
  margin-right: $spacing-1x;
  color: $opacity-disabled00;
}

.checkboxSubDisabled > p {
  color: $opacity-disabled00;
}

.termsCheckbox {
  margin-bottom: $spacing-3x;
  display: flex;
}

.submitRow {
  display: flex;
  align-items: center;
  margin: $spacing-3x 0;
}

.submitError {
  color: $color-red1000;
  margin: $spacing-4x 0;
  font-size: $font-xsmall;
  font-family: $font-inter;
}

.scrollNormal p {
  color: $color-neutral800 !important
}

.scrollErrorText p {
  color: $color-red1000 !important;
}

.termsContent {
  p {
    font-size: $font-xsmall !important;
  }
}

.prevBtn {
  padding: 10px 0px !important;
}

.printTermsBtn {
  padding: 0 !important;
}